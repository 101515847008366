@font-face {
    font-family: 'Nunito Sans';
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    src: url('./assets/fonts/Nunito_Sans/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Playfair Display';
    src: url('./assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    src: url('./assets/fonts/Playfair_Display/PlayfairDisplay-Italic-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Starstruck';
    src: url('./assets/fonts/Starstruck/Starstruck.ttf') format('truetype');
}